<template>
  <div :class="[backgroundColorGray ? 'bg-putty md:py-20 pt-12 pb-16' : 'md:my-20 mt-12 mb-16']">
    <CardSlider :breakpoints="breakpoints" :heading="heading">
      <LinkField
        v-for="({ id, linkit, image, underliner, tag }, index) in recommendations"
        :key="id"
        class="mb-12 md:mb-0"
        type-name="ContentRecommendationSlide"
        :tracking-name="heading"
        :block-position="blockPosition"
        :index="index"
        v-bind="linkit"
      >
        <FixedAspect :ratio="ratio">
          <CldnImg
            v-if="image[0]"
            :src="image[0].url"
            :alt="image[0].title"
            sizes="(min-width: 1440px) 568px, (min-width: 768px) 40vw, 90vw"
            :ratio="ratio"
            crop="fill"
          />
        </FixedAspect>
        <div class="mt-2">
          <em class="fluid-xs leading-tightest text-br-green not-italic block">{{ tag }}</em>
          <div class="mt-2 fluid-lg leading-tight font-medium">
            {{ underliner }}
          </div>
        </div>
      </LinkField>
    </CardSlider>
  </div>
</template>
<script>
import CldnImg from '@/components/CldnImg';
import FixedAspect from '@/components/common/FixedAspect';
import LinkField from '@/components/common/LinkField';
import CardSlider from '@/components/common/CardSlider';

export default {
  name: 'ContentRecommendationSlide',
  components: {
    CardSlider,
    CldnImg,
    FixedAspect,
    LinkField,
  },
  props: {
    heading: {
      type: String,
      default: '',
    },
    recommendations: {
      type: Array,
      default: () => [],
    },
    backgroundColorGray: {
      type: Boolean,
      default: false,
    },
    blockPosition: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      ratio: 2 / 3,
      breakpoints: [
        {
          minWindowWidth: 0,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
        {
          minWindowWidth: 768,
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      ],
    };
  },
};
</script>
